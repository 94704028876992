import { useState } from "react";
import Modal from "react-modal";
import Date221028 from "./schedules/Date221028";
import Date221103 from "./schedules/Date221103";
import Date221111 from "./schedules/Date221111";
import Date221216 from "./schedules/Date221216";
import Date221224 from "./schedules/Date221224";
import Date231201 from "./schedules/Date231201";
import Date241101 from "./schedules/Date241101";

function Schedule() {
    const [date221028ModalIsOpen, set221028ModalIsOpen] = useState(false);
    const [date221103ModalIsOpen, set221103ModalIsOpen] = useState(false);
    const [date221111ModalIsOpen, set221111ModalIsOpen] = useState(false);
    const [date221216ModalIsOpen, set221216ModalIsOpen] = useState(false);
    const [date221224ModalIsOpen, set221224ModalIsOpen] = useState(false);
    const [date231201ModalIsOpen, set231201ModalIsOpen] = useState(false);
    const [date241101ModalIsOpen, set241101ModalIsOpen] = useState(false);


    return (
        <div className="Schedule">
            <div>
                <h2>開催予定</h2>
                <div className="date241101 scheduled_date"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        set241101ModalIsOpen(true);
                    }}>
                    2024年11月1日(金)
                </div>
            </div>
            <div id="passed">
                <h2>終わったもの</h2>
                <div className="date221009 scheduled_date" >
                    2022年10月9日(日)
                </div>
                <div className="date221028 scheduled_date">
                    2022年10月28日(金)
                </div>
                <div className="date221103 scheduled_date">
                    2022年11月3日(木)
                </div>
                <div className="date221111 scheduled_date">
                    2022年11月11日(金)
                </div>
                <div className="date221216 scheduled_date">
                    2022年12月16日(金)
                </div>
                <div className="date221224 scheduled_date">
                    2022年12月24日(土)
                </div>
                <div className="date231201 scheduled_date">
                    2023年12月1日(金)
                </div>
            </div>

            <div id="mask" className="hidden"></div>
            {/* 221028 */}
            <Modal isOpen={date221028ModalIsOpen}>
                <div>
                    <Date221028 />
                </div>
                <div className="close" onClick={() => set221028ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            {/* 221103 */}
            <Modal isOpen={date221103ModalIsOpen}>
                <div>
                    <Date221103 />
                </div>
                <div className="close" onClick={() => set221103ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            {/* 221111 */}
            <Modal isOpen={date221111ModalIsOpen}>
                <div>
                    <Date221111 />
                </div>
                <div className="close" onClick={() => set221111ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date221216ModalIsOpen}>
                <div>
                    <Date221216 />
                </div>
                <div className="close" onClick={() => set221216ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date221224ModalIsOpen}>
                <div>
                    <Date221224 />
                </div>
                <div className="close" onClick={() => set221224ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date231201ModalIsOpen}>
                <div>
                    <Date231201 />
                </div>
                <div className="close" onClick={() => set231201ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
            <Modal isOpen={date241101ModalIsOpen}>
                <div>
                    <Date241101 />
                </div>
                <div className="close" onClick={() => set241101ModalIsOpen(false)}>
                    閉じる
                </div>
            </Modal>
        </div>
    );

}

export default Schedule;
